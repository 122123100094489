import image1 from "../../assets/images/San2Pay.png";
import image2 from "../../assets/images/vcnicon.png";
import image3 from "../../assets/images/transacticon.png";
// export const data = [image1, image2, image3 ];

export const data = [
    {
      src: image1,
      title: 'Scan2Pay'
    },
    {
      src: image2,
      title: 'VCard'
    },
    {
      src: image3,
      title: 'Transactions'
    },
  ];